import { BehaviorSubject, Observable } from "rxjs";
import { IColumnConfigService } from "../models/column-config-service.model";
import { IColumnConfig } from "../models/column-config.model";

export abstract class BaseColumnConfigService implements IColumnConfigService {
  protected abstract storageKey: string;
  protected abstract defaultConfigs: IColumnConfig[];

  private colsConfigSubject = new BehaviorSubject<IColumnConfig[]>(this.loadConfig());
  colsConfig$: Observable<IColumnConfig[]> = this.colsConfigSubject.asObservable();

  updateConfig(config: IColumnConfig[]): void {
    this.setConfigToStorage(config);
    this.colsConfigSubject.next(config);
  }

  resetConfig(): void {
    this.removeConfigFromStorage();
    this.colsConfigSubject.next(this.defaultConfigs);
  }
  getConfig(): IColumnConfig[] | null {
    return this.loadConfig();
  }

  private setConfigToStorage(configs: IColumnConfig[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(configs));
  }

  private removeConfigFromStorage(): void {
    localStorage.removeItem(this.storageKey);
  }

  private loadConfig(): IColumnConfig[] {
    let config = localStorage.getItem(this.storageKey);
    return config ? JSON.parse(config) : this.defaultConfigs;
  }
}
